require("@rails/ujs").start()

import Vue from 'vue'
import VueMeta from 'vue-meta'
import axios from 'axios'
import PortalVue from 'portal-vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighchartsMore from "highcharts/highcharts-more"
import { InertiaApp } from '@inertiajs/inertia-vue'
import { InertiaProgress } from '@inertiajs/progress'
import ActionCableVue from 'actioncable-vue'
import VueKonva from 'vue-konva'

Vue.use(VueKonva)

import Chartkick from 'vue-chartkick'

Vue.use(Chartkick.use(Highcharts))

const images = require.context('../images', true)
const imagePath = (name) => images(`./${name}`, true).default
Vue.prototype.imagePath = imagePath

Vue.use(require('vue-shortkey'), { prevent: ['input', 'textarea'] })

import '@/plugins/view-design'
import router from '@/plugins/router'
import '../styles/application.sass'
Vue.use(VueMeta)
Vue.use(router)

Vue.use(PortalVue)

Vue.use(HighchartsVue)

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectImmediately: true
})

import showFlash from '@/utils/flash'

Vue.use(InertiaApp)

InertiaProgress.init({
  delay: 250,
  color: '#29d',
  includeCSS: true,
  showSpinner: true
})

import store from '../store'

axios.defaults.xsrfHeaderName = 'X-CSRF-Token'

axios.defaults.headers.common['X-Axios'] = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

Vue.prototype.$axios = axios

import Breadcrumb from '@/components/breadcrumb'
Vue.component('c-breadcrumb', Breadcrumb)

const app = document.getElementById('app')

Vue.filter('formatCelcius', function (value) {
  if (!value) return `&#x2103;`
  value = value.toString()
  return `${value} &#x2103;`
})

new Vue({
  metaInfo: {
    titleTemplate: (title) => title ? `${title} - CBM Reports` : 'CBM Reports',
  },
  store,
  render: h => h(InertiaApp, {
    props: {
      initialPage: JSON.parse(app.dataset.page),
      resolveComponent: name => import(`@/pages/${name}`).then(module => module.default),
      transformProps: props => {
        Vue.nextTick(() => {
          showFlash(props)
        })
        return props
      }
    }
  })
}).$mount(app)
