/*
File generated by js-routes 1.4.9
Based on Rails 6.0.3.2 routes of Core::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// ahoy_engine => /ahoy
  // function(options)
  ahoy_engine: Utils.route([], {}, [2,[7,"/",false],[6,"ahoy",false]]),
// ahoy_engine.visits => /ahoy/visits(.:format)
  // function(options)
  ahoy_engine_visits: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"ahoy",false]],[7,"/",false]],[2,[6,"visits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ahoy_engine.events => /ahoy/events(.:format)
  // function(options)
  ahoy_engine_events: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"ahoy",false]],[7,"/",false]],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// api_account => /api/account/:id(.:format)
  // function(id, options)
  api_account: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_account_index => /api/account(.:format)
  // function(options)
  api_account_index: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_login => /api/login(.:format)
  // function(options)
  api_login: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_report => /api/reports/:id(.:format)
  // function(id, options)
  api_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_reports => /api/reports(.:format)
  // function(options)
  api_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_tag => /api/tags/:id(.:format)
  // function(id, options)
  api_tag: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_tags => /api/tags(.:format)
  // function(options)
  api_tags: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer => /blazer
  // function(options)
  blazer: Utils.route([], {}, [2,[7,"/",false],[6,"blazer",false]]),
// blazer.run_queries => /blazer/queries/run(.:format)
  // function(options)
  blazer_run_queries: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.cancel_queries => /blazer/queries/cancel(.:format)
  // function(options)
  blazer_cancel_queries: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.refresh_query => /blazer/queries/:id/refresh(.:format)
  // function(id, options)
  blazer_refresh_query: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refresh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blazer.tables_queries => /blazer/queries/tables(.:format)
  // function(options)
  blazer_tables_queries: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.schema_queries => /blazer/queries/schema(.:format)
  // function(options)
  blazer_schema_queries: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"schema",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.docs_queries => /blazer/queries/docs(.:format)
  // function(options)
  blazer_docs_queries: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"docs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.queries => /blazer/queries(.:format)
  // function(options)
  blazer_queries: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// blazer.new_query => /blazer/queries/new(.:format)
  // function(options)
  blazer_new_query: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.edit_query => /blazer/queries/:id/edit(.:format)
  // function(id, options)
  blazer_edit_query: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blazer.query => /blazer/queries/:id(.:format)
  // function(id, options)
  blazer_query: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.run_check => /blazer/checks/:id/run(.:format)
  // function(id, options)
  blazer_run_check: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blazer.checks => /blazer/checks(.:format)
  // function(options)
  blazer_checks: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"checks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// blazer.new_check => /blazer/checks/new(.:format)
  // function(options)
  blazer_new_check: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.edit_check => /blazer/checks/:id/edit(.:format)
  // function(id, options)
  blazer_edit_check: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blazer.check => /blazer/checks/:id(.:format)
  // function(id, options)
  blazer_check: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"checks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.refresh_dashboard => /blazer/dashboards/:id/refresh(.:format)
  // function(id, options)
  blazer_refresh_dashboard: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"refresh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blazer.dashboards => /blazer/dashboards(.:format)
  // function(options)
  blazer_dashboards: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// blazer.new_dashboard => /blazer/dashboards/new(.:format)
  // function(options)
  blazer_new_dashboard: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.edit_dashboard => /blazer/dashboards/:id/edit(.:format)
  // function(id, options)
  blazer_edit_dashboard: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blazer.dashboard => /blazer/dashboards/:id(.:format)
  // function(id, options)
  blazer_dashboard: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]],[2,[6,"dashboards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blazer.root => /blazer/
  // function(options)
  blazer_root: Utils.route([], {}, [2,[2,[7,"/",false],[6,"blazer",false]],[7,"/",false]]),
// c => /c/reports/:location(.:format)
  // function(location, options)
  c: Utils.route([["location",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"location",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// c_et_report => /c/et/reports/:id(.:format)
  // function(id, options)
  c_et_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"et",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// c_et_reports => /c/et/reports(.:format)
  // function(options)
  c_et_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"et",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// c_feedback => /c/feedbacks/:id(.:format)
  // function(id, options)
  c_feedback: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"feedbacks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// c_feedbacks => /c/feedbacks(.:format)
  // function(options)
  c_feedbacks: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"feedbacks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// c_ogis_report => /c/ogis/reports/:id(.:format)
  // function(id, options)
  c_ogis_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"ogis",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// c_ogis_reports => /c/ogis/reports(.:format)
  // function(options)
  c_ogis_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"ogis",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// c_re_report => /c/re/reports/:id(.:format)
  // function(id, options)
  c_re_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// c_re_reports => /c/re/reports(.:format)
  // function(options)
  c_re_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// c_service => /c/services/:id(.:format)
  // function(id, options)
  c_service: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// c_service_report => /c/services/:service_id/reports/:id(.:format)
  // function(service_id, id, options)
  c_service_report: Utils.route([["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// c_service_reports => /c/services/:service_id/reports(.:format)
  // function(service_id, options)
  c_service_reports: Utils.route([["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// c_services => /c/services(.:format)
  // function(options)
  c_services: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// c_vt_report => /c/vt/reports/:id(.:format)
  // function(id, options)
  c_vt_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"vt",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// c_vt_reports => /c/vt/reports(.:format)
  // function(options)
  c_vt_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"vt",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_staff_registration => /staff/cancel(.:format)
  // function(options)
  cancel_staff_registration: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cancel_user_registration => /user/cancel(.:format)
  // function(options)
  cancel_user_registration: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// complete_et_report => /ir/e/reports/:id/complete(.:format)
  // function(id, options)
  complete_et_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"complete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// dashboard_index => /dashboard(.:format)
  // function(options)
  dashboard_index: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// demo => /demo(.:format)
  // function(options)
  demo: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"demo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// destroy_staff_session => /staff/logout(.:format)
  // function(options)
  destroy_staff_session: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_user_session => /user/logout(.:format)
  // function(options)
  destroy_user_session: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// download => /download(.:format)
  // function(options)
  download: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// edit_api_account => /api/account/:id/edit(.:format)
  // function(id, options)
  edit_api_account: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_report => /api/reports/:id/edit(.:format)
  // function(id, options)
  edit_api_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_tag => /api/tags/:id/edit(.:format)
  // function(id, options)
  edit_api_tag: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_c_et_report => /c/et/reports/:id/edit(.:format)
  // function(id, options)
  edit_c_et_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"et",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_c_feedback => /c/feedbacks/:id/edit(.:format)
  // function(id, options)
  edit_c_feedback: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"feedbacks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_c_ogis_report => /c/ogis/reports/:id/edit(.:format)
  // function(id, options)
  edit_c_ogis_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"ogis",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_c_re_report => /c/re/reports/:id/edit(.:format)
  // function(id, options)
  edit_c_re_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_c_service => /c/services/:id/edit(.:format)
  // function(id, options)
  edit_c_service: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_c_service_report => /c/services/:service_id/reports/:id/edit(.:format)
  // function(service_id, id, options)
  edit_c_service_report: Utils.route([["service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_c_vt_report => /c/vt/reports/:id/edit(.:format)
  // function(id, options)
  edit_c_vt_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"vt",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_ee_report => /pd/e/reports/:id/edit(.:format)
  // function(id, options)
  edit_ee_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pd",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_et_report => /ir/e/reports/:id/edit(.:format)
  // function(id, options)
  edit_et_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_feedback => /feedbacks/:id/edit(.:format)
  // function(id, options)
  edit_feedback: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"feedbacks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_loa_report => /oa/l/reports/:id/edit(.:format)
  // function(id, options)
  edit_loa_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oa",false],[2,[7,"/",false],[2,[6,"l",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_location => /locations/:id/edit(.:format)
  // function(id, options)
  edit_location: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_ogis_report => /ir/o/reports/:id/edit(.:format)
  // function(id, options)
  edit_ogis_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"o",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_re_report => /pd/r/reports/:id/edit(.:format)
  // function(id, options)
  edit_re_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pd",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_st_client => /co/clients/:id/edit(.:format)
  // function(id, options)
  edit_st_client: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_st_client_user => /co/clients/:client_id/users/:id/edit(.:format)
  // function(client_id, id, options)
  edit_st_client_user: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_st_report => /co/reports/:id/edit(.:format)
  // function(id, options)
  edit_st_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_st_service => /co/services/:id/edit(.:format)
  // function(id, options)
  edit_st_service: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_st_suggestion => /co/suggestions/:id/edit(.:format)
  // function(id, options)
  edit_st_suggestion: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_staff_password => /staff/password/edit(.:format)
  // function(options)
  edit_staff_password: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_staff_registration => /staff/edit(.:format)
  // function(options)
  edit_staff_registration: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_toa_report => /oa/t/reports/:id/edit(.:format)
  // function(id, options)
  edit_toa_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oa",false],[2,[7,"/",false],[2,[6,"t",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_ums_staff => /ums/staffs/:id/edit(.:format)
  // function(id, options)
  edit_ums_staff: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ums",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_ums_user => /ums/users/:id/edit(.:format)
  // function(id, options)
  edit_ums_user: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ums",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_password => /user/password/edit(.:format)
  // function(options)
  edit_user_password: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_registration => /user/edit(.:format)
  // function(options)
  edit_user_registration: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_vt_report => /ir/v/reports/:id/edit(.:format)
  // function(id, options)
  edit_vt_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"v",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// ee_report => /pd/e/reports/:id(.:format)
  // function(id, options)
  ee_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pd",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// ee_reports => /pd/e/reports(.:format)
  // function(options)
  ee_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pd",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// et_approves => /ir/e/approves(.:format)
  // function(options)
  et_approves: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"approves",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// et_charts => /ir/e/charts(.:format)
  // function(options)
  et_charts: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"charts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// et_creates => /ir/e/creates(.:format)
  // function(options)
  et_creates: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"creates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// et_photo => /ir/e/photos/:id(.:format)
  // function(id, options)
  et_photo: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"photos",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// et_photo_section => /ir/e/photo_sections/:id(.:format)
  // function(id, options)
  et_photo_section: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"photo_sections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// et_report => /ir/e/reports/:id(.:format)
  // function(id, options)
  et_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// et_reports => /ir/e/reports(.:format)
  // function(options)
  et_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// et_sequences => /ir/e/sequences(.:format)
  // function(options)
  et_sequences: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"sequences",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// et_tags => /ir/e/tags(.:format)
  // function(options)
  et_tags: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// et_updates => /ir/e/updates(.:format)
  // function(options)
  et_updates: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"updates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// events => /events(.:format)
  // function(options)
  events: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// feedback => /feedbacks/:id(.:format)
  // function(id, options)
  feedback: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"feedbacks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// feedbacks => /feedbacks(.:format)
  // function(options)
  feedbacks: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"feedbacks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// files => /files(.:format)
  // function(options)
  files: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// loa_report => /oa/l/reports/:id(.:format)
  // function(id, options)
  loa_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oa",false],[2,[7,"/",false],[2,[6,"l",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// loa_reports => /oa/l/reports(.:format)
  // function(options)
  loa_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oa",false],[2,[7,"/",false],[2,[6,"l",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// location => /locations/:id(.:format)
  // function(id, options)
  location: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// locations => /locations(.:format)
  // function(options)
  locations: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// marker => /marker(.:format)
  // function(options)
  marker: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"marker",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// new_api_account => /api/account/new(.:format)
  // function(options)
  new_api_account: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_report => /api/reports/new(.:format)
  // function(options)
  new_api_report: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_tag => /api/tags/new(.:format)
  // function(options)
  new_api_tag: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_c_et_report => /c/et/reports/new(.:format)
  // function(options)
  new_c_et_report: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"et",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_c_feedback => /c/feedbacks/new(.:format)
  // function(options)
  new_c_feedback: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"feedbacks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_c_ogis_report => /c/ogis/reports/new(.:format)
  // function(options)
  new_c_ogis_report: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"ogis",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_c_re_report => /c/re/reports/new(.:format)
  // function(options)
  new_c_re_report: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"re",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_c_service => /c/services/new(.:format)
  // function(options)
  new_c_service: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_c_service_report => /c/services/:service_id/reports/new(.:format)
  // function(service_id, options)
  new_c_service_report: Utils.route([["service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"service_id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_c_vt_report => /c/vt/reports/new(.:format)
  // function(options)
  new_c_vt_report: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"vt",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_ee_report => /pd/e/reports/new(.:format)
  // function(options)
  new_ee_report: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pd",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_et_report => /ir/e/reports/new(.:format)
  // function(options)
  new_et_report: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_feedback => /feedbacks/new(.:format)
  // function(options)
  new_feedback: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"feedbacks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_loa_report => /oa/l/reports/new(.:format)
  // function(options)
  new_loa_report: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oa",false],[2,[7,"/",false],[2,[6,"l",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_location => /locations/new(.:format)
  // function(options)
  new_location: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_ogis_report => /ir/o/reports/new(.:format)
  // function(options)
  new_ogis_report: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"o",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_re_report => /pd/r/reports/new(.:format)
  // function(options)
  new_re_report: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pd",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_st_client => /co/clients/new(.:format)
  // function(options)
  new_st_client: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_st_client_user => /co/clients/:client_id/users/new(.:format)
  // function(client_id, options)
  new_st_client_user: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_st_report => /co/reports/new(.:format)
  // function(options)
  new_st_report: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_st_service => /co/services/new(.:format)
  // function(options)
  new_st_service: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_st_suggestion => /co/suggestions/new(.:format)
  // function(options)
  new_st_suggestion: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_staff_password => /staff/password/new(.:format)
  // function(options)
  new_staff_password: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_staff_registration => /staff/sign_up(.:format)
  // function(options)
  new_staff_registration: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_staff_session => /staff/login(.:format)
  // function(options)
  new_staff_session: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_toa_report => /oa/t/reports/new(.:format)
  // function(options)
  new_toa_report: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oa",false],[2,[7,"/",false],[2,[6,"t",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_ums_staff => /ums/staffs/new(.:format)
  // function(options)
  new_ums_staff: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ums",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_ums_user => /ums/users/new(.:format)
  // function(options)
  new_ums_user: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ums",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_password => /user/password/new(.:format)
  // function(options)
  new_user_password: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_registration => /user/sign_up(.:format)
  // function(options)
  new_user_registration: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /user/login(.:format)
  // function(options)
  new_user_session: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_vt_report => /ir/v/reports/new(.:format)
  // function(options)
  new_vt_report: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"v",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// ogis_report => /ir/o/reports/:id(.:format)
  // function(id, options)
  ogis_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"o",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// ogis_reports => /ir/o/reports(.:format)
  // function(options)
  ogis_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"o",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// re_report => /pd/r/reports/:id(.:format)
  // function(id, options)
  re_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pd",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// re_reports => /pd/r/reports(.:format)
  // function(options)
  re_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pd",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reserve => /reserves/:id(.:format)
  // function(id, options)
  reserve: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reserves",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reserves => /reserves(.:format)
  // function(options)
  reserves: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reserves",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// review_et_report => /ir/e/reports/:id/review(.:format)
  // function(id, options)
  review_et_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"e",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"review",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// root => /
  // function(options)
  root: Utils.route([], {}, [7,"/",false]),
// sand => /sand(.:format)
  // function(options)
  sand: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sand",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sequences => /sequences(.:format)
  // function(options)
  sequences: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sequences",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// service => /:id(.:format)
  // function(id, options)
  service: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// set_customer => /set-customer(.:format)
  // function(options)
  set_customer: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"set-customer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// st => /co/suggestions/t/:suggestion_type(.:format)
  // function(suggestion_type, options)
  st: Utils.route([["suggestion_type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[6,"t",false],[2,[7,"/",false],[2,[3,"suggestion_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// st_client => /co/clients/:id(.:format)
  // function(id, options)
  st_client: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// st_client_user => /co/clients/:client_id/users/:id(.:format)
  // function(client_id, id, options)
  st_client_user: Utils.route([["client_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// st_client_users => /co/clients/:client_id/users(.:format)
  // function(client_id, options)
  st_client_users: Utils.route([["client_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"clients",false],[2,[7,"/",false],[2,[3,"client_id",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// st_clients => /co/clients(.:format)
  // function(options)
  st_clients: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"clients",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// st_report => /co/reports/:id(.:format)
  // function(id, options)
  st_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// st_reports => /co/reports(.:format)
  // function(options)
  st_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// st_reports_client_service => /co/:client/:service/reports(.:format)
  // function(client, service, options)
  st_reports_client_service: Utils.route([["client",true],["service",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[3,"client",false],[2,[7,"/",false],[2,[3,"service",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// st_search_tag => /co/search/tags/:query(.:format)
  // function(query, options)
  st_search_tag: Utils.route([["query",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"query",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// st_service => /co/services/:id(.:format)
  // function(id, options)
  st_service: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// st_services => /co/services(.:format)
  // function(options)
  st_services: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// st_suggestion => /co/suggestions/:id(.:format)
  // function(id, options)
  st_suggestion: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"suggestions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// st_suggestions => /co/suggestions(.:format)
  // function(options)
  st_suggestions: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"suggestions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// st_vue => /co/vue(.:format)
  // function(options)
  st_vue: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"vue",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// st_wo_tags => /co/wo/:work_order/tags(.:format)
  // function(work_order, options)
  st_wo_tags: Utils.route([["work_order",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"wo",false],[2,[7,"/",false],[2,[3,"work_order",false],[2,[7,"/",false],[2,[6,"tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// staff_password => /staff/password(.:format)
  // function(options)
  staff_password: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// staff_registration => /staff(.:format)
  // function(options)
  staff_registration: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// staff_root => /
  // function(options)
  staff_root: Utils.route([], {}, [7,"/",false]),
// staff_session => /staff/login(.:format)
  // function(options)
  staff_session: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// status_st_report => /co/reports/:id/status(.:format)
  // function(id, options)
  status_st_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// timeline_st_reports => /co/reports/timeline(.:format)
  // function(options)
  timeline_st_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"co",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"timeline",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// toa_report => /oa/t/reports/:id(.:format)
  // function(id, options)
  toa_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oa",false],[2,[7,"/",false],[2,[6,"t",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// toa_reports => /oa/t/reports(.:format)
  // function(options)
  toa_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oa",false],[2,[7,"/",false],[2,[6,"t",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// ums => /ums(.:format)
  // function(options)
  ums: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ums",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ums_staff => /ums/staffs/:id(.:format)
  // function(id, options)
  ums_staff: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ums",false],[2,[7,"/",false],[2,[6,"staffs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// ums_staffs => /ums/staffs(.:format)
  // function(options)
  ums_staffs: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ums",false],[2,[7,"/",false],[2,[6,"staffs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ums_user => /ums/users/:id(.:format)
  // function(id, options)
  ums_user: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ums",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// ums_users => /ums/users(.:format)
  // function(options)
  ums_users: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ums",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_password => /user/password(.:format)
  // function(options)
  user_password: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_registration => /user(.:format)
  // function(options)
  user_registration: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_root => /
  // function(options)
  user_root: Utils.route([], {}, [7,"/",false]),
// user_session => /user/login(.:format)
  // function(options)
  user_session: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// vt_report => /ir/v/reports/:id(.:format)
  // function(id, options)
  vt_report: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"v",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// vt_reports => /ir/v/reports(.:format)
  // function(options)
  vt_reports: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ir",false],[2,[7,"/",false],[2,[6,"v",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);
