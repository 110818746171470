<template lang="pug">
  portal(to="breadcrumbs")
    Breadcrumb
      template(v-for="item in items")
        inertia-link(:href="item.href" v-if="item.href")
          BreadcrumbItem
            span {{item.name}}
        BreadcrumbItem(v-else)
          span {{item.name}}
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: false
    }
  }
}
</script>