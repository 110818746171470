import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    notes: []
  },
  mutations: {
    loadNotes(state, notes) {
      state.notes = notes
    }
  },
  actions: {
    async GET_NOTES({ commit }) {
      const { data } = await Vue.axios.get('/api/notes')
      commit('loadNotes', data)
    },
  },
  getters: {
    notes: state => state.notes
  }
})
